import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {graphql} from "gatsby";
import styles from "../templates/exhibition.module.css";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {options} from "../templates/renderer";
import heroStyles from "../components/hero.module.css";
import Img from "gatsby-image";

class AktuellIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [aktuell] = get(this, 'props.data.allContentfulAktuell.edges')

    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Aktuell'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
          <div className="with-padding">
            <h1>{aktuell.node.title}</h1>
            <div className='with-padding-on-mobile'>{renderRichText(aktuell.node.description, options)}</div>
          </div>
          </Layout>
    )
  }
}

export default AktuellIndex

export const pageQuery = graphql`
  query AktuellQuery {
      allContentfulAktuell(
          filter: { contentful_id: { eq: "2IYN0EXYcSnqEkfsJxT9wC" } }
        ) {
          edges {
              node {
                title
                titleShort
                description {
                    raw
                    references {
                      ... on ContentfulAsset {
                        contentful_id
                        description
                          __typename
                          fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid_withWebp
                          }
                        }
                    }
                }
              }
            }
      }
}
`
